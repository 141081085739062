import classNames from 'classnames';
import React from 'react';

import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import { FILE_TYPE } from '@/contexts/LibrarySetupContext/constants';

import Icon, { IconName, IconPath } from '@/components/npl/Icon';

import { AvailableEmbeddedVideoPlatformType } from '../types';

type DefaultFolderThumbnailProps = WithComponentClassName<{
  type: {
    fileType: keyof typeof FILE_TYPE;
    videoPlatform?: AvailableEmbeddedVideoPlatformType;
  };
}>;

const DefaultFolderThumbnail: React.FC<DefaultFolderThumbnailProps> = ({
  componentClassName,
  type
}) => {
  const { fileType, videoPlatform } = type;

  const mappedDefaultThumbnails: Record<
    keyof (typeof FILE_TYPE)[number],
    {
      iconName: IconName;
      path: IconPath;
      className?: string;
      iconFill?: string;
    }
  > = {
    [FILE_TYPE.EXTERNAL_LINK]: {
      iconName: 'link-01',
      iconFill: '#30A66D',
      className: 'bg-npl-success-light-2',
      path: 'common'
    },
    [FILE_TYPE.IMG]: {
      iconName: 'image-01',
      iconFill: '#F76808',
      className: 'bg-npl-orange-light-2',
      path: 'common'
    },
    [FILE_TYPE.VIDEO]: {
      iconName: 'video-recorder',
      iconFill: '#3C4FDD',
      className: 'bg-npl-blurple-light-2',
      path: 'common'
    },
    [FILE_TYPE.FILE]: {
      iconName: 'file-01',
      iconFill: '#D742A0',
      className: 'bg-npl-pink-light-2',
      path: 'common'
    },
    [FILE_TYPE.AUDIO]: {
      iconName: 'headphones-02',
      iconFill: '#3442B2',
      path: 'common'
    }
  };

  const mappedEmbeddedVideoThumbnails: Record<
    AvailableEmbeddedVideoPlatformType,
    {
      iconName: IconName<'social'>;
      className?: string;
    }
  > = {
    youtube: {
      iconName: 'youtube-coloured',
      className: 'bg-npl-error-light-2'
    },
    vimeo: {
      iconName: 'vimeo-coloured',
      className: 'bg-npl-sky-light-2'
    }
  };

  return (
    <div
      className={classNames(
        componentClassName,
        'rounded-8 border-npl-transparent-black-5 border-[0.5px] flex items-center justify-center w-60 h-60 min-h-[60px] min-w-[60px]',
        mappedDefaultThumbnails[fileType]?.className,
        videoPlatform &&
          mappedEmbeddedVideoThumbnails[videoPlatform]?.className
      )}>
      {mappedDefaultThumbnails[fileType] && (
        <Icon
          name={mappedDefaultThumbnails[fileType].iconName}
          fill={mappedDefaultThumbnails[fileType].iconFill}
          path={mappedDefaultThumbnails[fileType].path}
        />
      )}
      {videoPlatform && mappedEmbeddedVideoThumbnails[videoPlatform] && (
        <Icon
          name={mappedEmbeddedVideoThumbnails[videoPlatform].iconName}
          path="social"
        />
      )}
    </div>
  );
};

export default withComponentClassName(DefaultFolderThumbnail, false);
