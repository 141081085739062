import { formatSecondsToBrokenDownTime } from '@/utility/dateHelper';
import { formatMSToBrokenDownTime } from '@/utility/member/dataUtils';

import { FILE_TYPE } from '@/contexts/LibrarySetupContext/constants';

import {
  FOLDER_ITEM_TYPE_AUDIO,
  FOLDER_ITEM_TYPE_FILE,
  FOLDER_ITEM_TYPE_IMAGE,
  FOLDER_ITEM_TYPE_LINK,
  FOLDER_ITEM_TYPE_VIDEO,
  VIDEO_PLATFORMS
} from '@/pages/portal/products/constants';
import {
  formatSize,
  getFileExtension,
  getFileFormat
} from '@/pages/portal/products/utils';

function getFormatTypeOfFile(type) {
  switch (type) {
    case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
      return 'XLSX';
    case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT':
      return 'DOCX';
    case 'QUICKTIME':
      return 'MOV';
    case 'PLAIN':
      return 'TEXT';
    default:
      return type;
  }
}

function getMetadataFormat(item) {
  if (item?.format) {
    let formatFile = getFileFormat(item?.format)?.toUpperCase();
    formatFile = getFormatTypeOfFile(formatFile);

    return formatFile;
  } else {
    let format = getFileExtension(item?.link)?.toUpperCase();

    // to fix data issue where link is saved as "undefined"
    return format === 'UNDEFINED' ? 'VIDEO' : format;
  }
}

export function getMetadataList(item) {
  switch (item?.type) {
    case FOLDER_ITEM_TYPE_VIDEO:
      return [
        getMetadataFormat(item) || 'VIDEO',
        formatSecondsToBrokenDownTime(item?.duration)
      ];
    case FOLDER_ITEM_TYPE_FILE:
      return [getMetadataFormat(item), formatSize(item?.size)];
    case FOLDER_ITEM_TYPE_LINK:
      return ['URL'];
    case FILE_TYPE.EMBEDDED_VIDEO_LINK:
      const metadataList = [VIDEO_PLATFORMS[item?.platform] ?? 'URL'];

      if (item?.duration) {
        metadataList.push(formatMSToBrokenDownTime(item.duration * 1000));
      }
      return metadataList;
    case FOLDER_ITEM_TYPE_IMAGE:
      return [getMetadataFormat(item), formatSize(item?.size)];
    case FOLDER_ITEM_TYPE_AUDIO:
      [
        getMetadataFormat(item),
        formatSecondsToBrokenDownTime(item?.duration)
      ];
  }
}

export const getFirstNFolderItems = (folderItems, n) => {
  const maxCount = n;
  let currCount = 0;

  let result = [];
  let isMoreDataAvailable = false; // initialize

  // debugger;

  for (let i = 0; i < folderItems.length; i++) {
    if (currCount >= maxCount) {
      isMoreDataAvailable = true;
      break;
    }

    const folderItem = folderItems[i];

    // Section
    if (folderItem?.type === 'section') {
      const availableSpace = maxCount - currCount;
      const sectionItemsCount = folderItem?.items?.length || 0;
      if (availableSpace >= sectionItemsCount) {
        result.push(folderItem);
        currCount += sectionItemsCount;
      } else {
        const sectionItems = folderItem?.items?.slice(0, availableSpace);
        const section = { ...folderItem, items: sectionItems };
        result.push(section);
        currCount += availableSpace;
      }
    } else {
      // File | Not section
      result.push(folderItems[i]);
      currCount += 1;
    }
  }

  return { result, isMoreDataAvailable };
};

export const getFirstNSections = (folderItems, n) => {
  const maxCount = n;
  let currCount = 0;

  let result = [];
  let isMoreDataAvailable = false; // initialize

  for (let i = 0; i < folderItems.length; i++) {
    if (currCount >= maxCount) {
      isMoreDataAvailable = true;
      break;
    }

    result.push(folderItems[i]);
    currCount += 1;
  }

  return { result, isMoreDataAvailable };
};
