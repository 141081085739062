import { differenceInDays } from 'date-fns';

import { defaultHostProfileImage } from '@/pages/portal/events/constants';
import { isValidUrl } from '@/pages/portal/utils/events';

export const PLATFORM_TYPES = [
  'discord',
  'telegram',
  'whatsapp',
  'facebook',
  'slack',
  'linkedin'
];
export const [
  DISCORD_TYPE,
  TELEGRAM_TYPE,
  WHATSAPP_TYPE,
  FACEBOOK_TYPE,
  SLACK_TYPE,
  LINKEDIN_TYPE
] = PLATFORM_TYPES;
export const getSocialLinkFromPlatformData = (
  platformData,
  socialName
) => {
  if (platformData && platformData.length > 0) {
    const platform = platformData.find(
      (platform) => platform.name === socialName
    );
    return platform?.link;
  }
};
export const getDiscordLinkFromCommunityObj = (communityData) => {
  return getSocialLinkFromPlatformData(
    communityData?.platforms,
    DISCORD_TYPE
  );
};

export const getTelegramLinkFromCommunityObj = (communityData) => {
  return getSocialLinkFromPlatformData(
    communityData?.platforms,
    TELEGRAM_TYPE
  );
};

export const getWhatsAppLinkFromCommunityObj = (communityData) => {
  return getSocialLinkFromPlatformData(
    communityData?.platforms,
    WHATSAPP_TYPE
  );
};

export const getSocialLinksFromCommunityObj = (communityData) => {
  const discordLinks = communityData?.platforms?.find(
    ({ name }) => name === DISCORD_TYPE
  );
  const telegramLinks = communityData?.platforms?.find(
    ({ name }) => name === TELEGRAM_TYPE
  );

  const whatsappLinks = communityData?.platforms?.find(
    ({ name }) => name === WHATSAPP_TYPE
  );

  const facebookLinks = communityData?.platforms?.find(
    ({ name }) => name === FACEBOOK_TYPE
  );

  const slackLinks = communityData?.platforms?.find(
    ({ name }) => name === SLACK_TYPE
  );

  const linkedinLinks = communityData?.platforms?.find(
    ({ name }) => name === LINKEDIN_TYPE
  );

  return {
    discord: discordLinks?.link || '',
    telegram: telegramLinks?.link || '',
    whatsapp: whatsappLinks?.link || '',
    facebook: facebookLinks?.link || '',
    slack: slackLinks?.link || '',
    linkedin: linkedinLinks?.link || ''
  };
};

export const getHostImage = ({ url }) => {
  const isValid = isValidUrl({ url });
  if (!isValid) return defaultHostProfileImage;
  return url;
};

export const getLatestUpcomingEventWithinDays = ({
  events = [],
  days = 0
}) => {
  try {
    if (!events.length) return null;

    //Filter out all upcoming events with the days range
    //Return event that is not sold out or registered
    const allComingEvents = events.filter((event) => {
      const latestUpcomingStartDate = new Date(event?.startTime);
      const userLocalDate = new Date();
      const isRegistered = event?.registered;
      const isSoldOut = event?.isRegistrationClosed;
      return (
        differenceInDays(latestUpcomingStartDate, userLocalDate) <= days &&
        (!isSoldOut || isRegistered)
      );
    });
    const nextUpcomingEvent = allComingEvents?.[0];
    return nextUpcomingEvent;
  } catch (e) {
    console.error(e);
    return null;
  }
};
export const convertCourseTimeFromToMinAndHours = (timeInSecs) => {
  try {
    const hours = Math.floor(timeInSecs / 3600);
    const minutes = Math.floor((timeInSecs - hours * 3600) / 60);
    const remainingSecs = timeInSecs - hours * 3600 - minutes * 60;
    return {
      hours: hours > 0 ? hours : null,
      minutes: minutes > 0 ? minutes : null,
      secs: remainingSecs
    };
  } catch (e) {
    console.error(e);
  }
};
export function formatMSToBrokenDownTime(timeInMs) {
  try {
    const { hours, minutes, secs } = convertCourseTimeFromToMinAndHours(
      timeInMs / 1000
    );
    let formattedStr = '';
    if (hours) formattedStr += `${Math.trunc(hours)}h`;
    if (minutes) formattedStr += ` ${Math.trunc(minutes)}m`;
    if (secs) formattedStr += ` ${Math.trunc(secs)}s`;

    return formattedStr.trim();
  } catch (e) {
    console.error(e);
  }
}
